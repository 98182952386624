import React, {ComponentProps} from 'react';
import Button from '@material-ui/core/Button';

import {styled} from '@/theme';

interface Props {
  url: string;
  id: string;
  fileType?: 'json' | 'html' | 'pdf';
  documentType?: 'analysis' | 'summary' | 'bundle';
}

const ResultButton = styled((props: ComponentProps<typeof Button>) => (
  <Button
    variant="contained"
    fullWidth
    {...{target: '_blank', rel: 'noopener'}}
    {...props}
  />
))`
  text-transform: capitalize;
`;

const Uppercase = styled.span`
  text-transform: uppercase;
`;

export function Result({id, url, fileType, documentType}: Props) {
  return (
    <ResultButton href={url}>
      {documentType}&nbsp;<Uppercase>{fileType}</Uppercase>&nbsp;&mdash;&nbsp;
      {id}
    </ResultButton>
  );
}

export default Result;
