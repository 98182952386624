import React, {PropsWithChildren, useCallback, useMemo, useState} from 'react';

import {DemoContext, DemoContextType} from './context';

export function DemoProvider({
  children,
  ...options
}: PropsWithChildren<Partial<DemoContextType>>) {
  const [state, setState] = useState<DemoContextType>({
    page: 'welcome',
    start: () => {},
    ...options,
  });

  const start = useCallback(() => {
    setState((prev) => ({...prev, page: 'active'}));
    window.scrollTo(0, 0);
  }, []);

  const value = useMemo(() => ({...state, setState, start}), [start, state]);

  return <DemoContext.Provider value={value}>{children}</DemoContext.Provider>;
}

export default DemoProvider;
