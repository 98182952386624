import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We provide all information that we obtain from the Bank provided, as well as all analyses conducted. JSON bundles and HTML/PDF versions are made available as they are processed.`}</p>
    <p>{`On the far left of this window is the iFrame your customers will see, unless you do a complete API integration and customise the entire workflow. You can perform a test by selecting "Bank of TaleFin" and using the username "username" and password "password".`}</p>
    <p>{`Beside the iframe are the messages that will be webhook to your backend. They will stream in as events occur; in real-time. All this information can also be delivered via email for a more traditional setup.`}</p>
    <p>{`Above this text, buttons will appears as various components become available. It can take up to three minutes for all the components to arrive; you will be shown a dialog once everything has completed, so try to wait until the end.`}</p>
    <p>{`If you want to see what a component looks like, just click the button and a new tab will open. You can refresh this page to start again. If you click the instructions below the iFrame on the far left, you will see you can even test common errors and other features that might be interesting.`}</p>
    <p>{`All data is only kept for a few minutes and is stateless; so you can use a genuine bank account if you wish. The data will be purged and no one can see it, aside from you in this console.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      