import React, {ComponentProps} from 'react';
import {Button, Grid} from '@material-ui/core';

import {styled} from '@/theme';

export const StartNowButton = styled((props: ComponentProps<typeof Button>) => (
  <Button size="large" variant="contained" fullWidth {...props} />
))`
  font-size: 2em;
`;

export const DemoGrid = styled((props: ComponentProps<typeof Grid>) => (
  <Grid {...props} container />
))`
  justify-content: center;
  margin: ${({theme}) => theme.spacing(4)} 0;
`;

export const DemoColumn = styled((props: ComponentProps<typeof Grid>) => (
  <Grid {...props} container item />
))`
  flex: 1 1 33.33%;
  flex-direction: column;

  & + & {
    padding-left: 1em;
  }
`;

export const DemoIframe = styled.iframe`
  flex: 1 1 auto;
  border: 0;
  overflow: hidden;

  html,
  body {
    overflow: hidden;
  }
`;

export const ResultsList = styled(Grid)`
  flex: 1 1 auto;
  overflow-y: auto;
`;
