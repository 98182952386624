import React from 'react';
import {
  Router,
  Location,
  HistoryLocation,
  RouteComponentProps,
} from '@reach/router';

import ActiveDemo from '@/components/Demo/ActiveDemo';
import DemoProvider from '@/components/Demo/provider';
import Page404 from '@/pages/404';

interface DemoRouteProps {
  path: string;
  uri: string;
  location: HistoryLocation;
  uuid: string;
}

function Route({uuid}: RouteComponentProps<DemoRouteProps>) {
  if (!uuid) {
    return <Page404 />;
  }

  return (
    <DemoProvider uuid={uuid}>
      <ActiveDemo />
    </DemoProvider>
  );
}

export function Demo() {
  return (
    <Location>
      {({location}) => (
        <Router location={location} basepath="/demo">
          <Route path="/" />
          <Route path="/:uuid" />
        </Router>
      )}
    </Location>
  );
}

export default Demo;
