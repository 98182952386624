export enum Lifecycle {
  INITIAL = 'INITIAL',
  QUEUED = 'QUEUED',
  PROCESSING = 'PROCESSING',
  AUTH = 'AUTH',
  RELEASED = 'RELEASED',
  COMPLETED = 'COMPLETED',
  EXITED = 'EXITED',
  FAILED = 'FAILED',
  TIMEDOUT = 'TIMEDOUT',
  DIED = 'DIED',
}

export interface CrawlerDetails {
  code: number;
  message?: string;
}

export interface CrawlerState {
  code: CrawlerDetails;
  status: Lifecycle;
  overflow?: boolean | null;
  message?: {
    title?: string;
    details: CrawlerDetails;
    standardError: CrawlerDetails;
    inputs: Record<string, any>;
  };
  pending_messages?: {
    sequence: number;
    message: {
      sequence: number;
      types: string[];
      payload: {
        title: string;
        inputs: Record<string, any>;
      };
    };
  }[];
}

export interface WebhookEvent {
  event: string;
  payload: {
    id: string;
    time: string;
    vendor_label: string;
    vendor_specific_id: string;
    vendor_tag?: string;
    status?: Lifecycle;
  };
}
