import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
import PageTitle from '@/components/PageTitle';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle mdxType="PageTitle">
      <h1 {...{
        "id": "welcome-to-the-talefin-demo",
        "style": {
          "position": "relative"
        }
      }}>{`Welcome to the TaleFin Demo`}<a parentName="h1" {...{
          "href": "#welcome-to-the-talefin-demo",
          "aria-label": "welcome to the talefin demo permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h1>
    </PageTitle>
    <h2 {...{
      "id": "what-is-this",
      "style": {
        "position": "relative"
      }
    }}>{`What is this?`}<a parentName="h2" {...{
        "href": "#what-is-this",
        "aria-label": "what is this permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`This is a demonstration tool that let's you test our bank statement analysis
technology with hand crafted data that produces nice metrics.
You will have the opportunity to use our iFrame and experience the customer
journey for yourself. `}</p>
    <p>{`For developers, we have also provided examples of our
webhooks, exactly as your backend system would receive them.`}</p>
    <h2 {...{
      "id": "how-do-i-use-it",
      "style": {
        "position": "relative"
      }
    }}>{`How do I use it?`}<a parentName="h2" {...{
        "href": "#how-do-i-use-it",
        "aria-label": "how do i use it permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`At the bottom of this page you can start an application with "Bank of TaleFin",
our very own low interest bank for testing purposes. It provides a selection of
accounts to test the metrics and some special usernames that can trigger various
use cases.`}</p>
    <h2 {...{
      "id": "what-can-i-use-it-for",
      "style": {
        "position": "relative"
      }
    }}>{`What can I use it for?`}<a parentName="h2" {...{
        "href": "#what-can-i-use-it-for",
        "aria-label": "what can i use it for permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You will be able to induce all kinds of real-world features and user errors. You
will also be able to see how our webhooks behave, as well as see example payloads
and analysed statements. To test more complex features, you can follow the additional
details in the email you were sent containing this link.`}</p>
    <h2 {...{
      "id": "is-this-product-customisable",
      "style": {
        "position": "relative"
      }
    }}>{`Is this product customisable?`}<a parentName="h2" {...{
        "href": "#is-this-product-customisable",
        "aria-label": "is this product customisable permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Absolutely. You can customise the product to seamlessly fit into your existing
customer journey. For example, you can change the font and colours of the iFrame,
You can also deep link to skip some screens and even prepopulate fields.`}</p>
    <p>{`We also have an API available for enterprise vendors.`}</p>
    <h2 {...{
      "id": "ready-to-get-started",
      "style": {
        "position": "relative"
      }
    }}>{`Ready to get started?`}<a parentName="h2" {...{
        "href": "#ready-to-get-started",
        "aria-label": "ready to get started permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Great! Click the start button below. Bank of TaleFin has lots of different usernames and
passwords, all of which are listed on the following page. The most basic ones are;`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Username`}</strong>{`: `}<inlineCode parentName="li">{`username`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Password`}</strong>{`: `}<inlineCode parentName="li">{`password`}</inlineCode></li>
    </ul>
    <p>{`If you get stuck or want to start again, just refresh this entire page. It's stateless and resets fresh on each load.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      