import React, {
  ComponentProps,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactJson from 'react-json-view';
import {useCookie, useInterval} from 'react-use';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {match, when} from 'ts-pattern';
import {navigate} from 'gatsby-link';
import {DateTime} from 'luxon';

import Main from '@/templates/Main';
import {HOST, API_HOST, API_ROOT} from '@/config';

import {DemoContext} from './context';
import Welcome from './welcome.mdx';
import Results from './results.mdx';
import {
  DemoColumn,
  DemoGrid,
  DemoIframe,
  ResultsList,
  StartNowButton,
} from './styled';
import {Lifecycle, WebhookEvent} from './types';
import Result from './Result';
import ResultsPlaceholder from './ResultsPlaceholder';

type Artifact = ComponentProps<typeof Result>;

export function ActiveDemo() {
  const {uuid, page, start} = useContext(DemoContext);
  const [webhooks, setWebhooks] = useState<WebhookEvent[]>([]);
  const [height, setHeight] = useState<number>();
  const [loadingCount, setLoadingCount] = useState(true);
  const [csrf] = useCookie('csrftoken');
  const vendorSpecificId = useMemo(
    () => [uuid, DateTime.now().toFormat('X')].join('-'),
    [uuid],
  );

  const isCompleted = useMemo(
    () =>
      !![webhooks?.[0]].filter(
        (result) =>
          /lifecycle_updated$/.test(result?.event) &&
          result.payload.status === Lifecycle.COMPLETED,
      )?.length,
    [webhooks],
  );

  const results = useMemo<Artifact[]>(
    () =>
      webhooks
        .filter((result) => /_ready$/.test(result.event))
        .map((result) => {
          const fileType = match(result)
            .with({event: when((v) => /html_ready$/.test(v))}, () => 'html')
            .with({event: when((v) => /pdf_ready$/.test(v))}, () => 'pdf')
            .otherwise(() => 'json') as Artifact['fileType'];

          const documentType = match(result)
            .with({event: when((v) => /analysis/.test(v))}, () => 'analysis')
            .with({event: when((v) => /summary/.test(v))}, () => 'summary')
            .otherwise(() => 'bundle') as Artifact['documentType'];

          const isV1 = (v: string) => v.includes('v1');
          const isV2 = (v: string) => v.includes('v2');

          const location = match({documentType, root: API_ROOT})
            .with(
              {documentType: 'summary'},
              () => `applications/${result.payload.id}/summary/${fileType}`,
            )
            .with(
              {documentType: 'analysis', root: when(isV1)},
              () => `analyses/${result.payload.id}/report/${fileType}`,
            )
            .with(
              {documentType: 'analysis', root: when(isV2)},
              () => `analyses/${result.payload.id}/${fileType}`,
            )
            .with(
              {documentType: 'bundle'},
              () => `applications/${result.payload.id}/bundle`,
            )
            .exhaustive();

          const url = `${API_HOST}/${API_ROOT}/demo/redirect?path=/${API_ROOT}/${location}`;

          return {
            id: result.payload.id,
            url: API_ROOT.includes('v1') ? url.replace(/\/json$/, '') : url,
            fileType,
            documentType,
          };
        }),
    [webhooks],
  );

  const listenerRef = useRef(function messageListener(
    event: MessageEvent<any>,
  ) {
    if (event?.data?.type === 'resize') {
      const newHeight = event?.data?.height;
      setHeight(newHeight ? newHeight : 100);
    }

    if (event?.data?.type === 'loaded') {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    const currentRef = listenerRef.current;
    window.addEventListener('message', currentRef);

    return () => {
      window.removeEventListener('message', currentRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    if (page !== 'active') {
      return;
    }

    const response = await fetch(
      `${API_HOST}/${API_ROOT}/demo/webhooks/${vendorSpecificId}`,
    );
    const data: WebhookEvent[] = await response.json();
    setWebhooks(data);
  }, 2000);

  useEffect(() => {
    fetch(`${API_HOST}/${API_ROOT}/demo/${uuid}/counter/`, {
      headers: {'X-CSRFToken': csrf || ''},
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) {
          navigate('/404');
        } else {
          setLoadingCount(false);
        }
      })
      .catch(() => {
        navigate('/404');
      });
  }, [csrf, uuid]);

  if (!vendorSpecificId) {
    null;
  }

  if (loadingCount) {
    return (
      <Main>
        <div style={{textAlign: 'center', margin: '4em 0'}}>
          <CircularProgress size="4rem" />
        </div>
      </Main>
    );
  }

  if (page === 'welcome') {
    return (
      <Main>
        <Welcome start={start} />
        <Box paddingTop={2} paddingBottom={8}>
          <StartNowButton onClick={start}>Start Now!</StartNowButton>
        </Box>
      </Main>
    );
  }

  return (
    <Main contained={false}>
      <DemoGrid>
        <DemoColumn>
          <Typography variant="h5" textAlign="center" gutterBottom>
            Customer Facing iFrame
          </Typography>
          <DemoIframe
            title="TaleFin iFrame Demo"
            src={`${HOST}/i/demo/${vendorSpecificId}`}
            style={{height}}
          />
        </DemoColumn>
        <DemoColumn>
          <Typography variant="h5" textAlign="center" gutterBottom>
            Webhooks to Your Backend
          </Typography>
          <Box paddingBottom={4}>
            <ReactJson
              src={webhooks}
              style={{fontSize: '0.75em'}}
              name={false}
            />
          </Box>
        </DemoColumn>
        <DemoColumn>
          <Typography variant="h5" textAlign="center" gutterBottom>
            Processed Results
          </Typography>
          <Grid item>
            <ResultsList container spacing={1} height={320}>
              {!results.length && (
                <Grid item height={300} width="100%" padding={1} flexGrow={0}>
                  <ResultsPlaceholder loading={isCompleted} />
                </Grid>
              )}
              <Grid
                item
                container
                padding={1}
                spacing={1}
                alignContent="flex-start"
              >
                {results.map((props) => (
                  <Grid
                    item
                    xs={6}
                    key={`${props.id}-${props.documentType}-${props.fileType}`}
                  >
                    <Result {...props} />
                  </Grid>
                ))}
              </Grid>
            </ResultsList>
            <Results />
          </Grid>
        </DemoColumn>
      </DemoGrid>
    </Main>
  );
}

export default ActiveDemo;
